import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { SingleSelectComponent } from './single-select/single-select.component';
import { ArrayMapModule } from './array-map/array-map.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [CommonModule, ArrayMapModule, IonicModule],
  declarations: [CustomDropdownComponent, SingleSelectComponent, MultiSelectComponent],
  exports: [CustomDropdownComponent, SingleSelectComponent, MultiSelectComponent],
})
export class CustomDropdownsModule {}
