import { Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleSelectComponent),
      multi: true,
    },
  ],
})
export class SingleSelectComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder: string;
  @Input() hasStaticPlaceholder: boolean;
  @Input() idProp = 'id';
  @Input() selectedDisplayValueProp: string;
  @Input() options: any[];
  @Input() optionTpl: TemplateRef<any>;
  @Input() disabled: boolean;
  @Input() sortFn: any;
  @Input() deselectable: boolean;
  @Input() inverse: boolean;
  @Input() hiddenIcons: boolean;

  @Output() optionSelect: EventEmitter<any> = new EventEmitter();

  selectedOption: any;

  private tmpValue: any;

  get value() {
    return this.tmpValue;
  }

  set value(newValue: any) {
    this.tmpValue = newValue;
    this.propagateChange(this.tmpValue);
  }

  ngOnInit() {}

  onSelect(options: any[]) {
    const firstOption = options[0];

    if (this.deselectable && !!this.selectedOption && !!firstOption &&
        (this.selectedOption === firstOption || this.selectedOption[this.idProp] === firstOption[this.idProp])) {
      this.selectedOption = null;
    } else {
      this.selectedOption = firstOption;
    }

    this.propagateChange(this.selectedOption);
    this.optionSelect.emit(this.selectedOption);
  }

  onCrossMatchOptions(selectedOptions: any[]) {
    const firstOption = selectedOptions[0];
    this.propagateChange(firstOption);
  }

  writeValue(obj: any): void {
    this.tmpValue = obj;
    this.selectedOption = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_fn: any): void {}

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => {};
}
