import { Component, OnInit } from '@angular/core';
import { LookupItem } from 'src/app/core/models/lookup-item.model';
import { BookHelperService } from 'src/app/core/helpers/book-helper.service';

@Component({
  selector: 'app-book-form',
  templateUrl: './book-form.component.html',
  styleUrls: ['./book-form.component.scss'],
})
export class BookFormComponent implements OnInit {

  startDate: Date;
  endDate: Date;
  selectedRoomType: LookupItem;

  roomTypes = [
    {id: '1', description: 'Single'} as LookupItem,
    {id: '2', description: 'Double'} as LookupItem
  ];

  constructor(private bookHelper: BookHelperService) { }

  ngOnInit() {}

  onStartDateChange(value: any) {
    this.startDate = new Date(value.detail.value);
  }

  onEndDateChange(value: any) {
    this.endDate = new Date(value.detail.value);
  }

  onSelectRoomType(option: LookupItem) {
    this.selectedRoomType = option;
  }

  async onBook() {
    this.bookHelper.book();
  }

}
