export const sort = (arr: any[], key?: string, desc?: boolean) => {
  if (!!arr && !!arr.length) {
    return arr.slice().sort((a, b) => {
      const predicate = desc ? (x, y) => (x < y ? 1 : -1) : (x, y) => (x > y ? 1 : -1);

      if (!!key) {
        const aNum = parseInt(a[key], 10);
        const bNum = parseInt(b[key], 10);
        if (!aNum || !bNum) {
          return predicate(a[key]?.toLowerCase(), b[key]?.toLowerCase());
        } else {
          return predicate(aNum, bNum);
        }
      } else {
        return predicate(a, b);
      }
    });
  }

  return arr;
};
