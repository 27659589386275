import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BookFormComponent } from './book-form.component';
import { CustomDropdownsModule } from '../custom-dropdowns/custom-dropdowns.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    CustomDropdownsModule
  ],
  declarations: [BookFormComponent],
  exports: [BookFormComponent],
})
export class BookFormModule { }
