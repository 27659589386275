import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NavigationHelperService {

  constructor(private alertController: AlertController) { }

  async showUnderConstructionAlert(message = 'This page is under construction') {
    const alert = await this.alertController.create({
      header: 'Under Construction',
      cssClass: 'custom-alert',
      message,
      buttons: ['OK']
    });

    await alert.present();
  }

}
