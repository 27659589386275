import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { sort } from 'src/app/utils/array-utils';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholder: string;
  @Input() itemsLabel: string;
  @Input() selectedOptions: any[];
  @Input() options: any[];
  @Input() optionTpl: TemplateRef<any>;
  @Input() allOptionsTpl: TemplateRef<any>;
  @Input() selectedDisplayValueProp: string;
  @Input() closeOnSelect: boolean;
  @Input() allowMultipleValues: boolean;
  @Input() hasStaticPlaceholder: boolean;
  @Input() disabled: boolean;
  @Input() sortFn: any;
  @Input() inverse: boolean;
  @Input() hiddenIcons: boolean;
  @Input() deselectable = true;
  @Input() idProp = 'id';

  @Output() optionsSelect = new EventEmitter<any[]>();
  @Output() optionsCrossMatch = new EventEmitter<any[]>();

  selectedOptionValue: string;
  isOpen: boolean;
  crossMatchTimeout: any;

  constructor(private host: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (!this.host.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    clearTimeout(this.crossMatchTimeout);
  }

  ngOnChanges(changes: SimpleChanges) {
    const { options, selectedOptions, selectedDisplayValueProp } = changes;
    if (!!options && !!options.currentValue) {
      this.options = [...this.getSortedOptions(this.options)];
    }

    if (
      (!!options && !!options.currentValue) ||
      (!!selectedOptions && !!selectedOptions.currentValue) ||
      (!!selectedDisplayValueProp && !!selectedDisplayValueProp.currentValue)
    ) {
      if (this.options && !!(this.selectedOptions || []).length) {
        const crossMatchedSelectedOptions = this.getCrossMatchedSelectedOptions(this.selectedOptions, this.options);
        if (crossMatchedSelectedOptions.length !== this.selectedOptions.length) {
          clearTimeout(this.crossMatchTimeout);
          this.crossMatchTimeout = setTimeout(() => {
            this.selectedOptions = crossMatchedSelectedOptions;
            this.optionsCrossMatch.emit(this.selectedOptions);
          });
        }
      }

      this.calcSelectedOptionValue(this.options, this.selectedOptions, this.selectedDisplayValueProp);
    }
  }

  toggle(e: Event) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  onOptionSelect(event: Event, selectedOption: any) {
    if (!!event) {
      event.stopPropagation();
    }

    if (!this.selectedOptions) {
      this.selectedOptions = [];
    }

    let options = [];

    if (this.allowMultipleValues) {
      const isSelected = this.getSelectedOptionPredicate(this.selectedOptions, selectedOption);
      if (!!isSelected()) {
        options = [...this.selectedOptions.filter(o => o[this.idProp] !== selectedOption[this.idProp])];
      } else {
        options = [...this.selectedOptions, selectedOption];
      }
    } else {
      const isSelected = this.getSelectedOptionPredicate(this.selectedOptions, selectedOption);
      if (this.deselectable && !!isSelected()) {
        options = [];
      } else {
        options = [selectedOption];
      }
    }

    if (this.closeOnSelect) {
      this.close();
    }

    this.calcSelectedOptionValue(this.options, options, this.selectedDisplayValueProp);
    this.optionsSelect.emit(options);
  }

  onAllOptionsToggle() {
    if (!!this.selectedOptions && this.selectedOptions.length === this.options.length) {
      this.selectedOptions = [];
    } else {
      this.selectedOptions = this.options;
    }

    if (this.closeOnSelect) {
      this.close();
    }

    this.calcSelectedOptionValue(this.options, this.selectedOptions, this.selectedDisplayValueProp);
    this.optionsSelect.emit(this.selectedOptions);
  }

  itemsTrackByFn(index, item: any) {
    if (!!this.selectedDisplayValueProp) {
      return item[this.selectedDisplayValueProp];
    } else if (this.idProp) {
      return item[this.idProp];
    } else {
      return item;
    }
  }

  private getSelectedOptionPredicate(selectedOptions: any, selectedOption: any): any {
    if (!!this.selectedDisplayValueProp || !!this.idProp) {
      return () =>
        (selectedOptions || []).some(o => (o[this.selectedDisplayValueProp] === selectedOption[this.selectedDisplayValueProp]) ||
        (o[this.idProp] === selectedOption[this.idProp]));
    } else {
      return () => (selectedOptions || []).indexOf(selectedOption) !== -1;
    }
  }

  private getCrossMatchedSelectedOptions(selectedOptions: any[], options: any[]) {
    const matchedSelectedOptions = selectedOptions
      .filter(selectedOption => options.some(option => option[this.idProp] === selectedOption[this.idProp]));
    return matchedSelectedOptions;
  }

  private calcSelectedOptionValue(options: any[], selectedOptions: any[], selectedDisplayValueProp: string) {
    if (!this.hasStaticPlaceholder && !!selectedOptions && !!selectedOptions.length) {
      if (selectedOptions.length > 1) {
        const itemsWord = 'items';
        const selectedWord = 'selected';

        if (!!options) {
          if (selectedOptions.length === this.options.length) {
            const allWord = 'All';
            this.selectedOptionValue = `${allWord} ${(this.itemsLabel || itemsWord).toLowerCase()} ${selectedWord}`;
          } else {
            this.selectedOptionValue = `${selectedOptions.length} ${(this.itemsLabel || itemsWord).toLowerCase()} ${selectedWord}`;
          }
        }
      } else {
        const selectedOption = selectedOptions[0];
        if (!!selectedOption) {
          const option = (options || []).find(opt => (
            (opt[this.idProp] && opt[this.idProp] === selectedOption[this.idProp]) ||
            (opt[this.selectedDisplayValueProp] && opt[this.selectedDisplayValueProp] === selectedOption[this.selectedDisplayValueProp]) ||
            (opt === selectedOption)
          ));
          this.selectedOptionValue = !!option && !!selectedDisplayValueProp ? option[selectedDisplayValueProp] : option;
        }
      }
    } else {
      this.selectedOptionValue = this.placeholder;
    }
  }

  private getSortedOptions(options: any[]): any[] {
    if (!!this.sortFn) {
      return this.sortFn(options);
    } else if (this.selectedDisplayValueProp) {
      return sort(options, this.selectedDisplayValueProp);
    } else {
      return sort(options);
    }
  }

  private open() {
    this.isOpen = true;
  }

  private close() {
    this.isOpen = false;
  }
}
