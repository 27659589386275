import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder: string;
  @Input() idProp = 'id';
  @Input() selectedDisplayValueProp: string;
  @Input() options: any[];
  @Input() itemsLabel = 'items';
  @Input() sortFn: any;
  @Input() hasStaticPlaceholder: boolean;
  @Input() disabled: boolean;
  @Input() inverse: boolean;

  @Output() optionsSelect: EventEmitter<any[]> = new EventEmitter();

  selectedOptions: any[];

  private tmpValue: any[];

  get value() {
    return this.tmpValue;
  }

  set value(newValue: any) {
    this.tmpValue = newValue;
    this.propagateChange(this.tmpValue);
  }

  ngOnInit() {}

  onSelect(options: any[]) {
    this.selectedOptions = options;
    this.propagateChange(this.selectedOptions);
    this.optionsSelect.emit(this.selectedOptions);
  }

  onCrossMatchOptions(selectedOptions: any[]) {
    this.propagateChange(selectedOptions);
  }

  writeValue(values: any[]): void {
    this.tmpValue = values;
    this.selectedOptions = values;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_fn: any): void {}

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => {};
}
