import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { copyToClipboard } from 'src/app/utils/general-utils';

@Injectable({
  providedIn: 'root'
})
export class BookHelperService {

  constructor(private alertController: AlertController,
    private toastController: ToastController) { }

  async book() {
    const alert = await this.alertController.create({
      header: 'Book via Email',
      subHeader: 'The booking system is under construction, for now please send an email to:',
      cssClass: 'custom-alert',
      message: 'info@olivasuites.gr',
      buttons: [
        {
          text: 'Copy the Email',
          handler: () => {
            this.copyEmail();
          }
        }
      ]
    });

    await alert.present();
  }

  async copyEmail() {
    copyToClipboard('info@olivasuites.gr');
    this.presentToast('Copied info@olivasuites.gr  [Hint: Use Ctrl+V to paste it]', 'top');
  }

  async presentToast(message: string, position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message,
      duration: 5000,
      position,
      color: 'warning'
    });

    await toast.present();
  }

}
