import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayMap',
})
export class ArrayMapPipe implements PipeTransform {
  transform(value: any[], propertyName: string): any[] {
    return value.map(item => item[propertyName]);
  }
}
