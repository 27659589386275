import { Component } from '@angular/core';
import { NavigationHelperService } from './core/helpers/navigation-helper.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  currentYear: number;

  constructor(private navigationHelper: NavigationHelperService) {
    this.initCurrentYear();
  }

  showUnderConstruction() {
    this.navigationHelper.showUnderConstructionAlert();
  }

  private initCurrentYear() {
    this.currentYear = new Date().getFullYear();
  }
}
